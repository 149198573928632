import React, { Component } from 'react'
import { Link, navigate } from 'gatsby'
import SEO from '../components/SEO'

import withContext from '../lib/withContext'

class errorPage extends Component {
  componentDidMount() {
    navigate('/')
  }

  render() {
    const metaData = {
      title: 'Error page not found',
      image: undefined
    }

    return (
      <div>
        <SEO metaData={metaData} />
        <main>
          <h1>Error page not found</h1>
          <Link to="/">Go back to home</Link>
        </main>
      </div>
    )
  }
}

export default withContext(errorPage)
